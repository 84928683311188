@import "../../../../styles/foundation/config";

.genreSearch {
  background: #eff0eb;
  display: block;
  width: 168px;
  height: auto;
  padding: 14px 12px 12px;
  box-sizing: border-box;

  &_header {
    font-size: 14px;
    &::before {
      margin-right: 10px;
      font-family: 'Font Awesome 5 Free';
      content: '\f002';
      font-weight: inherit;
    }
  }
  &_list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 8px 0 0 0;
  }

  &_item {
    margin-right: 0;
    &_anchor {
      display: inline-block;
      width: 100%;
      padding: 4px 8px;
      text-decoration: none;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: bold;
      border-radius: 2px;
      line-height: inherit;
      &:hover {
        background-color: #dfe1d8;
      }
    }
  }
}

.headerGenreSearch {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  background: #eff0eb;
  padding: 8px 0 8px 13px;
  &_list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &_item {
    word-break: keep-all;
    margin-right: 12px;

    &_anchor {
      display: flex;
      width: 100%;
      padding: 0 12px;
      height: 31px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      background-color: #fff;
      font-size: 15px;
      border-radius: 2px;
      line-height: 1;
    }
  }
}

.detailSearch {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  background: #fff;
  text-decoration: none;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 2px;
  &:hover {
    background-color: #dfe1d8;
  }
}
