// Color palettes
// usage - palette($palette-group, $palette-type);
$palettes: (
  'scheme': (
    'default'              : #000,
    'theme'                : #f15353,
    'theme-hover'          : #f79898,
    'light-theme'          : #ffdfdf,
    'black'                : #000,
    'light-black'          : #282728,
    'gray'                 : #8e8e93,
    'custom-gray'          : #efeff4,
    'medium-gray'          : #c7c7cc,
    'light-gray-primary'   : #e5e5ea,
    'light-gray-secondary' : #d1d1d6,
    'red'                  : #e60012,
    'sky-blue'             : #00afec,
    'ultramarine-blue'     : #4051b6,
    'ash-blue'             : #ced3ed,
    'corn-yellow'          : #f9c049,
    'check-green'          : #4cd864,
    'rank-gold'            : #cb9822,
    'rank-silver'          : #8e8e93,
    'rank-bronze'          : #a17652,
    'pixiv'                : #137fce,
    'syosetu'              : #18b7cd,
    'header-gray'          : #ededed,
    'twitter'              : #1da1f2,
  ),
  'schema2': (
    'MediumGray'          : #6F6F6F,
    'LightGray'           : #BABABA,
    'DarkGray'            : #1D1D1D,
    'Gray'                : #F5F5F5,
    'DisableGray'         : #DDDDDD,
    'CautionRed'          : #E70006,
    'ButtonRed'           : #E13030
  )
);
@function palette($palette-group, $palette-type: 'default') {
  @return map-get(map-get($palettes, $palette-group), $palette-type);
}


// Font Setting
$ff-ja: '游ゴシック', YuGothic, 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
$ff-en: 'Helvetica Neue', Helvetica, sans-serif;

// Media Queries
$mq-breakpoints: (
  'xs': 375px,
  'sm': 560px,
  'md': 768px,
  'single':900px,
  'lg': 960px,
  'xl': 1200px,
);
@mixin mq($breakpoint: md, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint), print {
    @content;
  }
}
